<template>
<div
 id="profile-icon-show"
>
    <svg xmlns="http://www.w3.org/2000/svg" width="36.013" height="36" viewBox="0 0 37.013 37">
  <g id="Group_2466" data-name="Group 2466" transform="translate(-1848 2412)">
    <g id="account" transform="translate(1848 -2412)">
      <path id="Path_714" data-name="Path 714" d="M21.8,4a17.8,17.8,0,1,0,17.8,17.8A17.8,17.8,0,0,0,21.8,4Z" transform="translate(-3.304 -3.304)" fill="#fff" class="transformcr" />
      <path id="Path_715" data-name="Path 715" d="M0,0H37V37H0Z" fill="none"/>
    </g>
    <g id="account-2" data-name="account" transform="translate(1848.013 -2412)">
      <path id="profileicon-notshow" data-name="Path 714" d="M21.8,4a17.8,17.8,0,1,0,17.8,17.8A17.8,17.8,0,0,0,21.8,4Zm0,5.341a5.341,5.341,0,1,1-5.341,5.341A5.345,5.345,0,0,1,21.8,9.341Zm0,25.281a12.82,12.82,0,0,1-10.682-5.733c.045-3.534,7.13-5.483,10.682-5.483s10.629,1.949,10.682,5.483A12.82,12.82,0,0,1,21.8,34.622Z" transform="translate(-3.304 -3.304)" fill="#fff" class="light-dark-cr" />
      <path id="profileicon-anyshow" data-name="Path 715" d="M0,0H37V37H0Z" fill="none"/>
    </g>
  </g>
</svg>
</div>
</template>

<script>
export default{
    name :"Profileicon",
}

</script>

<style lang="scss">
 @import "@/sass/_variables.scss";
 @import "@/sass/_components.scss";

 .light-dark-cr{
    fill: $clr-profile-bg;
 }
 .transformcr{
  fill: $clr-saina-login-btn;
 }
</style>